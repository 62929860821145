import React, { useEffect, useState } from "react";
import apiAgent from "../../api/apiAgent";
import "../../css/report.css";
import CoverInfo from "./CoverInfo";
import Pricing from "./Pricing";
import Specs from "./Specs";
import {
  FaFacebookSquare,
  FaWhatsappSquare,
  FaTwitterSquare,
} from "react-icons/fa";
import { ImGoogle2 } from "react-icons/im";
import { IoMdMail } from "react-icons/io";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";

const Reports = () => {
  const [model, setModel] = useState();
  const [coverInformation, setcoverInformation] = useState();
  const [pricing, setpricing] = useState();
  const [specs, setspecs] = useState();
  const { lookup, userTheme } = apiAgent();
  const [themeData, setThemeData] = useState(userTheme.clientInfo());
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
    setThemeData(userTheme.clientInfo());
    const curURL = window.location.href;
    const reportKeyfn = curURL.split("?k=")[1];
    const reportKey = reportKeyfn.split("&ref=")[0];
    lookup.report(reportKey).then((res) => {
      setModel(res.response);
    });
  }, []);
  useEffect(() => {
    if (model) {
      setcoverInformation(model.coverInformation);
      setpricing(model.pricing);
      setspecs(model.specs);
    }
  }, [model]);

  if (!model) {
    return <div className="loader"></div>;
  }
  return (
    <div className="app m-1 p-2">
      <Row style={{ position: "absolute", right: "10px" }}>
        <Col xs={12}>
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={4000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">Report Generated Successfully</strong>
            </Toast.Header>
            {/* <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body> */}
          </Toast>
        </Col>
      </Row>
      <div className="row mt-1">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
          <div className="img_grid d-flex justify-content-center">
            <div className="logo_part">
              <img
                src={themeData.client_logo}
                alt="logo"
                id="logo"
                className="logo_img"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-12 col-sm-12 col-md-6">
          <div className="details mt-5">
            <div className="report_headings">
              <h2
                style={{ color: themeData.theme_settings.primary_color }}
              >{`${model.year} ${model.make} ${model.model}`}</h2>
              <h4>{model.variant}</h4>
            </div>
            <div className="report_details mt-4">
              {pricing && <Pricing themeData={themeData} pricing={pricing} />}
              {specs && <Specs themeData={themeData} specs={specs} />}
              {coverInformation && (
                <CoverInfo
                  themeData={themeData}
                  coverInformation={coverInformation}
                />
              )}
            </div>
          </div>
          <hr />
          <div className="share_links mt-4 d-flex align-items-center justify-content-center flex-wrap">
            <div className="social_link mx-2 my-1 ">
              <IoMdMail
                style={{
                  color: themeData.theme_settings.primary_color,
                  width: "40px",
                  height: "40px",
                }}
              />
            </div>
            <div className="social_link mx-2 my-1 ">
              <FaFacebookSquare
                style={{
                  color: themeData.theme_settings.primary_color,
                  width: "35px",
                  height: "35px",
                }}
              />
            </div>
            <div className="social_link mx-2 my-1 ">
              <ImGoogle2
                style={{
                  color: themeData.theme_settings.primary_color,
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            <div className="social_link mx-2 my-1 ">
              <FaTwitterSquare
                style={{
                  color: themeData.theme_settings.primary_color,
                  width: "35px",
                  height: "35px",
                }}
              />
            </div>
            <div className="social_link mx-2 my-1 ">
              <FaWhatsappSquare
                style={{
                  color: themeData.theme_settings.primary_color,
                  width: "35px",
                  height: "35px",
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

export default Reports;
