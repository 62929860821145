import React from "react";
import { IoMdCash } from "react-icons/io";

const Pricing = ({ pricing, themeData }) => {
  const priceFormat = (value) => {
    return "R "+ value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  return (
    <>
      <div className="price_details">
        <div className="d-flex justify-content-between">
          <h3 style={{ color: themeData.theme_settings.primary_color }}>
            Actual Pricing
          </h3>
          <IoMdCash style={{ color: themeData.theme_settings.primary_color }} />
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>New Price</h5>
          <span>{priceFormat(pricing.newPrice)}</span>
        </div>
        {/* <div className="d-flex justify-content-between my-3">
          <h5>Currency</h5>
          <span>{pricing.currency}</span>
        </div> */}
        <div className="d-flex justify-content-between my-3">
          <h5>Retail Price</h5>
          <span>{priceFormat(pricing.retailPrice)}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Trade Price</h5>
          <span>{priceFormat(pricing.tradePrice)}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Market Price</h5>
          <span>{priceFormat(pricing.marketPrice)}</span>
        </div>
      </div>
    </>
  );
};

export default Pricing;
