import React from "react";
import { FaRegQuestionCircle } from "react-icons/fa";

const Specs = ({ specs, themeData }) => {
  return (
    <>
      <div className="saleby_location_details mt-4">
        <div className="d-flex justify-content-between my-3">
          <h3 style={{ color: themeData.theme_settings.primary_color }}>
            Default Specifications
          </h3>
          <FaRegQuestionCircle style={{color:themeData.theme_settings.primary_color}}/>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Air Bags</h5>
          <span>{specs.airBags === true ? "Yes" : "No"}</span>
        </div>

        <div className="d-flex justify-content-between my-3">
          <h5>Aircon</h5>
          <span>{specs.aircon === true ? "Yes" : "No"}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Central Locking</h5>
          <span>{specs.centralLocking === true ? "Yes" : "No"}</span>
        </div>

        <div className="d-flex justify-content-between my-3">
          <h5>Average Fuel consumption</h5>
          <span>{specs.consumptionAnnualAverage}l p/km</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Electric Windows</h5>
          <span>{specs.electricWindows === true ? "Yes" : "No"}</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Maintenance Plan Distance</h5>
          <span>{specs.maintenancePlanDistance}km</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>Maintenance Plan Duration</h5>
          <span>{specs.maintenancePlanPeriod} months</span>
        </div>
        <div className="d-flex justify-content-between my-3">
          <h5>warranty Distance</h5>
          <span>{specs.warrantyDistance}km</span>
        </div>

        <div className="d-flex justify-content-between my-3">
          <h5>warranty Duration</h5>
          <span>{specs.warrantyPeriod} months</span>
        </div>
      </div>
    </>
  );
};

export default Specs;
